import { observer } from "mobx-react-lite";
import { rootStore, gameStore } from "@/store";
import { Routes, Route } from "react-router-dom";
import { isDevelopment, isProduction, isTelegram } from "@/utils";
import clsx from "clsx";
import "@mysten/dapp-kit/dist/index.css";
import Loader from "@/layout/Loader";
import LockScreen from "./layout/LockScreen";
import ErrorHint from "@/components/ErrorHint";
import DevOverlay from "./layout/DevOverlay";
import TelegramHeaderManager from "./components/TelegramHeaderManager";
import ToastContainer from "./components/ToastContainer/ToastContainer";
import CapybaraLayout from "./layout/CapybaraLayout";
import { menuItems } from "./constants";
import { LeagueLevelUpModal } from "./layout/LevelUpModal";
import Story from "./layout/Story";
import WalletLayout from "./layout/WalletLayout";
import WalletAuthLayout from "./layout/WalletAuthLayout";
import { AuthWalletsFlow } from "./components/AuthWallets";
import { StashedAuth } from "./components/CreateWallet/components/StashedAuth";
import { StashedAuthAndTransfer } from "./components/CreateWallet/components/StashedAuthAndTransfer";
import WalletTransfer from "./views/WalletTransfer";
import { StashedTransfer } from "./components/CreateWallet/components/StashedTransfer";
import { AutoFeedBotEarnedModal } from "./views/Boost/Modals/AutoFeedBotEarned";
import WalletBuy from "./views/WalletBuy";
import WalletPage from "./views/WalletPage";

const App = observer(() => {
  const className = clsx("appLayout", { ["isDevelopment"]: isDevelopment });
  if (window.location.pathname === "/auth") {
    return (
      <WalletAuthLayout>
        <StashedAuthAndTransfer />
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/authWallets") {
    return (
      <WalletAuthLayout>
        <AuthWalletsFlow />
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/authStashed") {
    return (
      <WalletAuthLayout>
        <StashedAuth />
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/transferStashed") {
    return (
      <WalletAuthLayout>
        <StashedTransfer />
      </WalletAuthLayout>
    );
  }

  return (
    <div className={className}>
      {isTelegram && <TelegramHeaderManager />}
      {rootStore.error && <ErrorHint />}
      {rootStore.qr && <LockScreen />}
      {rootStore.loading && !rootStore.qr && <Loader />}
      {/* <ImproveScreen /> */}
      {rootStore.gameReady && (
        <>
          {!isProduction && gameStore.devOverlay && <DevOverlay />}
          <ToastContainer />
          <Story />
          <LeagueLevelUpModal />
          <AutoFeedBotEarnedModal />
          <Routes>
            <Route
              key={"wallet"}
              path="/wallet"
              element={
                <WalletLayout>
                  <WalletPage />
                </WalletLayout>
              }
            />
            <Route
              key={"WalletTransfer"}
              path="/WalletTransfer"
              element={
                <WalletLayout>
                  <WalletTransfer />
                </WalletLayout>
              }
            />
            <Route
              key={"WalletBuy"}
              path="/WalletBuy"
              element={
                <WalletLayout>
                  <WalletBuy />
                </WalletLayout>
              }
            />

            {menuItems.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={
                  <CapybaraLayout>
                    <item.component />
                  </CapybaraLayout>
                }
              />
            ))}
          </Routes>
        </>
      )}
    </div>
  );
});

export default App;
