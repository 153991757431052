import "./initializer";
import "@/polyfills";
import "@/locale/i18n";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "@/styles/index.sass";
import "./utils/sentry.ts";
import store from "@/store";
import { BrowserRouter } from "react-router-dom";
import { networkConfig } from "./networkConfig.ts";
import { SuiClientProvider, WalletProvider } from "@mysten/dapp-kit";
import { EnokiFlowProvider } from "@mysten/enoki/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

store.init();
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <SuiClientProvider networks={networkConfig} defaultNetwork="mainnet">
          <WalletProvider
            autoConnect
            stashedWallet={{
              name: "My Wallet",
            }}
          >
            <EnokiFlowProvider
              apiKey={"enoki_public_063a0a58b76cf45bdc97af84039c4d21"}
            >
              <App />
            </EnokiFlowProvider>
          </WalletProvider>
        </SuiClientProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
