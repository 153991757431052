import { useEffect } from "react";
import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import {
  useCurrentAccount,
  useSuiClient,
  useSuiClientQuery,
} from "@mysten/dapp-kit";
import rootStore, { walletStore } from "@/store";

let walletInterval: NodeJS.Timeout;

const WalletLayout = observer(({ children }: { children: React.ReactNode }) => {
  const currentAccount = useCurrentAccount();
  const client = useSuiClient();
  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#fff");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  const { data, isFetched } = useSuiClientQuery("getBalance", {
    owner: currentAccount?.address as string,
  });

  useEffect(() => {
    rootStore.setLoading(true);
    if (isFetched) {
      rootStore.setLoading(false);
      walletStore.setBalance(data?.totalBalance as string);
    }

    walletInterval = setInterval(() => {
      client
        .getBalance({
          owner: currentAccount?.address as string,
        })
        .then((data) => {
          walletStore.setBalance(data?.totalBalance as string);
        });
    }, 15000);

    return () => {
      clearInterval(walletInterval);
    };
  }, [isFetched]);

  return (
    <div
      style={{
        background: "#fff",
      }}
      className={styles.layout}
    >
      {isFetched && children}
    </div>
  );
});

export default WalletLayout;
